import { defineStore } from "pinia";
import { ref } from "vue";
import { useSnackbarStore } from "@/stores/snackbar/snackbar";
import { orgStructureApi, projectsApi } from "@/api";
import type {
  ProjectDTO,
  UpdateProjectDTO,
} from "@boostbiz/project_service/client/project-schema";
import { useUserStore } from "@/stores/user/user";

export const useProjectsStore = defineStore("projects", () => {
  const projects = ref<ProjectDTO[]>([]);
  const selectedProjectId = ref<string>("");
  const isLoadingProjects = ref(false);
  const { showSnackbar } = useSnackbarStore();
  const userStore = useUserStore();

  const createProject = async (name: string) => {
    try {
      const id = await projectsApi.projects.create({
        name,
      });

      localStorage.setItem("project-id", id);

      getProjects();
      showSnackbar("Проект успешно создан");
    } catch (e) {
      showSnackbar("Не удалось создать проект");
      throw new Error();
    }
  };

  const removeProject = async (id: string) => {
    try {
      await projectsApi.projects.deleteById(id);
      await getProjects();

      showSnackbar("Проект успешно удален");
    } catch (e) {
      showSnackbar("Не удалось удалить проект");
      throw new Error();
    }
  };

  const updateProject = async (
    id: string,
    { name, color }: UpdateProjectDTO,
  ) => {
    try {
      await projectsApi.projects.updateById(id, {
        name,
        color,
      });
      await getProjects();

      showSnackbar("Проект успешно обновлен");
    } catch (e) {
      showSnackbar("Не удалось обновить проект");
      throw new Error();
    }
  };

  const setFirstProject = () => {
    selectedProjectId.value = projects.value[0].id;
    localStorage.setItem("project-id", projects.value[0].id);
  };

  const getProjects = async () => {
    isLoadingProjects.value = true;

    try {
      projects.value = userStore.user?.owner
        ? await projectsApi.projects.getAll()
        : await orgStructureApi.getListProjects();

      if (!projects.value.length) {
        isLoadingProjects.value = false;

        return;
      }

      const localProjectId = localStorage.getItem("project-id");

      if (localProjectId) {
        const project = projects.value.find(
          (item) => item.id === localProjectId,
        );

        if (project) {
          selectedProjectId.value = localProjectId;

          isLoadingProjects.value = false;

          return;
        }
      }

      setFirstProject();
    } catch (e) {
      showSnackbar("Не удалось загрузить проекты");
    }

    isLoadingProjects.value = false;
  };

  // watch(
  //   () => userStore.user,
  //   () => {
  //     if (userStore.user) {
  //       getProjects();
  //     }
  //   },
  // );

  return {
    projects,
    selectedProjectId,
    updateProject,
    removeProject,
    getProjects,
    createProject,
    isLoadingProjects,
  };
});
