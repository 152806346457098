import axios, { type AxiosInstance } from "axios";
import { AuthAPI } from "@boostbiz/auth";
import { useProjectsStore } from "@/stores/projects/projects";

const axiosInstance: AxiosInstance = axios.create({
  adapter: "fetch",
});

axiosInstance.interceptors.request.use((config) => {
  const projectsStore = useProjectsStore();

  config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  config.headers["projectId"] = projectsStore.selectedProjectId;

  return config;
});

const axiosInstanceRefresh = axios.create({
  withCredentials: true,
});

const authApiForRefreshUpdate = new AuthAPI(axiosInstanceRefresh);

axiosInstance.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;

    // if (
    //   error?.response?.status == 401 &&
    //   error?.config &&
    //   !error?.config._isRetry
    // ) {
    //   originalRequest._isRetry = true;
    //   try {
    //     // Обновление токенов
    //     const tokenResponse =
    //       await authApiForRefreshUpdate.common.refreshToken();
    //
    //     localStorage.setItem("token", tokenResponse.accessToken);
    //     originalRequest.headers["Authorization"] =
    //       `Bearer ${localStorage.getItem("token")}`;
    //
    //     return axiosInstanceRefresh.request(originalRequest);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }

    return Promise.reject(error);
  },
);

export default axiosInstance;
