<template>
  <navigation-layout
    :class="{ '!translate-x-0': isSidebarOpened }"
    :level="2"
    class="translate-x-full duration-200"
    is-sub-layout
    title="Редактировать тестирование"
  >
    <template #header>
      <button @click="handleBackClick">
        <img
          alt=""
          class="img--hoar absolute left-4 top-1/2 h-5 w-5 -translate-y-1/2 cursor-pointer"
          src="@/assets/icons/direction/arrow-right-long.svg"
        />
      </button>
    </template>

    <template #default>
      <div class="m-1.5">
        <settings-questions-tabs />
      </div>
      <div class="flex gap-1.5 border-y border-y-lunar p-1.5 justify-around">
        <action-button
          v-for="action in filteredEditActions"
          :key="action.value"
          :action="action"
          :active="action.value === EditAction.EDIT"
          :disabled="isPreviewLoading || isStartLoading || isEditLoading"
          :loading="resolveCurrentActionLoading(action.value as EditAction)"
          @click="resolveCurrentAction(action.value as EditAction)"
        />
      </div>
    </template>
  </navigation-layout>
</template>
<script lang="ts" setup>
import { computed, ref } from "vue";
import NavigationLayout from "@/layouts/navigation/navigation-layout.vue";
import { useSidebarStore } from "@/stores/sidebar/sidebar";
import { storeToRefs } from "pinia";
import { SidebarType } from "@/layouts/sidebars/sidebars.types";
import SettingsQuestionsTabs from "@/layouts/sidebars/knowledge-base-create-sidebar/settings-questions-tabs.vue";
import {
  EDIT_ACTIONS,
  EditAction,
} from "@/layouts/sidebars/knowledge-base-create-sidebar/knowledge-testing-survey-sidebar.data";
import ActionButton from "@/modules/knowledge-base/views/testing-survey/components/action-button.vue";
import { useKnowledgeBaseStore } from "@/stores/knowledge-base/knowledge-base";
import { testingApi } from "@/api";
import { useSnackbarStore } from "@/stores/snackbar/snackbar";
import { useRoute, useRouter } from "vue-router";
import { Route } from "@/router/router.types";
import { QuestionFormStatuses } from "@boostbiz/testing_form_microservice/client/consts";
import type { QuestionFormDTO } from "@boostbiz/question_form_microservice/client/schemas";
import { TestingTab } from "@/modules/knowledge-base/views/testing-survey/testing/tabs/testing-tabs.data";
import { SnackbarStatus } from "@/stores/snackbar/snackbar.types";

const router = useRouter();
const route = useRoute();

const { showSnackbar } = useSnackbarStore();

const sidebarStore = useSidebarStore();
const knowledgeBaseStore = useKnowledgeBaseStore();

const { currentSidebars } = storeToRefs(sidebarStore);

const isSidebarOpened = computed(
  () =>
    currentSidebars.value[currentSidebars.value.length - 1] ===
    SidebarType.KNOWLEDGE_BASE_TESTING_EDIT,
);

const handleBackClick = () => {
  const confirmed = window.confirm("Вы уверены, что хотите завершить редактирование?\nВсе изменения будут утеряны.");

  if (confirmed) {
    sidebarStore.goBack();
    router.push({
      path: `/${Route.KNOWLEDGE_BASE_TESTING}`,
      query: {
        tab: TestingTab.TESTS_LIST,
        page: 1,
        count: 10,
        search: "",
      },
    });
  }
};

const filteredEditActions = computed(() => {
  return knowledgeBaseStore.test.formStatus === QuestionFormStatuses.active
    ? EDIT_ACTIONS.filter(act => act.value !== EditAction.START)
    : EDIT_ACTIONS
})

const isEditLoading = ref(false);
const isPreviewLoading = ref(false);
const isStartLoading = ref(false);

const resolveCurrentActionLoading = (action: EditAction) => {
  switch (action) {
    case EditAction.EDIT:
      return isEditLoading.value;
    case EditAction.START:
      return isStartLoading.value;
    case EditAction.PREVIEW:
      return isPreviewLoading.value;
  }
};

const editTest = async () => {
  const shouldValidate =
    knowledgeBaseStore.test.formStatus !== QuestionFormStatuses.draft;

  if (
    (shouldValidate &&
      knowledgeBaseStore.testFormRef!.validate() &&
      knowledgeBaseStore.testQuestionsRef!.validate()) ||
    !shouldValidate
  ) {
    isEditLoading.value = true;

    try {
      await testingApi.actions.updateTestingForm(
        route.params.id.toString(),
        knowledgeBaseStore.test,
      );

      showSnackbar("Тестирование успешно отредактированно");
      router.push({
        path: `/${Route.KNOWLEDGE_BASE_TESTING}`,
        query: {
          tab: TestingTab.TESTS_LIST,
          page: 1,
          count: 10,
          search: "",
        },
      });
    } catch (e) {
      showSnackbar("Не удалось изменить тестирование", SnackbarStatus.ERROR);
      console.error(e);
    } finally {
      isEditLoading.value = false;
    }
  }
};

const startTest = async () => {
  if (
    knowledgeBaseStore.testFormRef!.validate() &&
    knowledgeBaseStore.testQuestionsRef!.validate()
  ) {
    isStartLoading.value = true;
    try {
      await testingApi.actions.startByOwner(knowledgeBaseStore.test.id);

      knowledgeBaseStore.test.formStatus = QuestionFormStatuses.active;
      showSnackbar("Тестирование успешно запущено");
    } catch (e) {
      showSnackbar("Не удалось запустить тестирование", SnackbarStatus.ERROR);
      console.error(e);
    } finally {
      isStartLoading.value = false;
    }
  }
};

const previewTest = async () => {
  isPreviewLoading.value = true;
  knowledgeBaseStore.testPreview =
    knowledgeBaseStore.test as unknown as QuestionFormDTO;
  await router.push(`/${Route.KNOWLEDGE_BASE_TESTING_PREVIEW}`);
  isPreviewLoading.value = false
};

const resolveCurrentAction = (action: EditAction) => {
  switch (action) {
    case EditAction.EDIT:
      return editTest();
    case EditAction.START:
      return startTest();
    case EditAction.PREVIEW:
      return previewTest();
  }
};
</script>

<style lang="scss" scoped></style>
