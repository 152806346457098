<template>
  <navigation-layout
    :class="{ '!translate-x-0': isSidebarOpened }"
    :level="1"
    class="translate-x-full duration-200"
    is-sub-layout
    @click-title="router.push(`/${Route.KNOWLEDGE_BASE}`)"
  >
    <template #header>
      <app-button
        class="absolute left-2.5 top-1/2 w-10 -translate-y-1/2 !p-0"
        icon
        size="small"
        type="texted"
        variant="tertiary"
        @click="handleBackClick"
      >
        <img
          alt=""
          class="img--hoar !h-5 !w-5"
          src="@/assets/icons/direction/arrow-right-long.svg"
        />
      </app-button>
      <router-link :to="`/${Route.KNOWLEDGE_BASE}`" class="text-16 font-medium"
        >База знаний
      </router-link>
    </template>

    <div class="flex flex-col gap-[2px] p-[6px]">
      <router-link
        v-for="route in routes"
        :key="route.title"
        :to="route.to"
        active-class="bg-hoar/10 !text-base group"
        class="flex h-9 w-full items-center gap-2 rounded-[10px] px-2.5 py-2 text-hoar hover:bg-hoar/10 active:bg-hoar/15"
      >
        <img
          :src="route.icon"
          alt=""
          class="img--hoar group-[]:img--primary h-[18px] w-[18px]"
        />
        <p class="text-14 font-semibold">{{ route.title }}</p>
      </router-link>
    </div>

    <div
      class="flex min-h-10 items-center justify-between border-b border-t border-lunar px-4 py-2"
    >
      <span class="text-12 font-semibold text-hoar">Документы</span>
      <app-menu
        :options="ADD_DOCUMENT_MENU_OPTIONS"
        list-styles="w-[174px]"
        placement="right-start"
      >
        <button
          class="flex h-8 w-8 items-center justify-center rounded-[10px] hover:bg-hoar/15"
        >
          <img
            alt=""
            class="img--primary h-[18px] w-[18px]"
            src="../../assets/icons/plus-circle.svg"
          />
        </button>
      </app-menu>
    </div>

    <div class="h-full overflow-y-auto">
      <div class="flex flex-col">
        <knowledge-base-navigation-item
          v-for="documentTree in sidebarDocuments"
          :key="documentTree.id"
          v-model="collapsedDocumentsIds"
          :item="documentTree"
          :items="sidebarDocuments"
        />
      </div>
    </div>
  </navigation-layout>
</template>
<script lang="ts" setup>
import notebookIcon from "@/assets/icons/knowledge-base-notebook.svg";
import notebookOpenIcon from "@/assets/icons/notebook-open.svg";
import notebookNotifyIcon from "@/assets/icons/knowledge-base-notebook-notify.svg";
import userIcon from "@/assets/icons/user/user-octagon.svg";
import { Route } from "@/router/router.types";
import { computed, nextTick, ref, watch } from "vue";
import { useKnowledgeBaseStore } from "@/stores/knowledge-base/knowledge-base";
import { getDocumentIcon, getNewTitle } from "@/views/knowledge-base/utils";
import {
  type DocumentTree,
  DocumentType,
} from "@boostbiz/wiki_client/schemas/documents/documents.schema";
import AppMenu from "@/shared/UIKit/app-menu/app-menu.vue";
import type { MenuOption } from "@/shared/UIKit/app-menu/app-menu.types";
import KnowledgeBaseNavigationItem from "@/views/knowledge-base/components/knowledge-base-navigation/knowledge-base-navigation-item.vue";
import cloneDeep from "clone-deep";
import NavigationLayout from "@/layouts/navigation/navigation-layout.vue";
import { useSidebarStore } from "@/stores/sidebar/sidebar";
import { storeToRefs } from "pinia";
import { SidebarType } from "@/layouts/sidebars/sidebars.types";
import { useRouter } from "vue-router";
import { TestingTab } from "@/modules/knowledge-base/views/testing-survey/testing/tabs/testing-tabs.data";
import AppButton from "@/shared/UIKit/app-button/app-button.vue";

const sidebarStore = useSidebarStore();
const { currentSidebars } = storeToRefs(sidebarStore);
const router = useRouter();
const knowledgeBaseStore = useKnowledgeBaseStore();
const sidebarDocuments = ref<DocumentTree[]>(
  cloneDeep(knowledgeBaseStore.documentTree),
);
const collapsedDocumentsIds = ref<number[]>([]);

const isSidebarOpened = computed(
  () =>
    currentSidebars.value[currentSidebars.value.length - 1] ===
    SidebarType.KNOWLEDGE_BASE,
);

watch(
  () => knowledgeBaseStore.mainUpdateToggle,
  async () => {
    sidebarDocuments.value = cloneDeep(knowledgeBaseStore.documentTree);
  },
);

knowledgeBaseStore.fetchDocumentTree().then(() => {
  knowledgeBaseStore.mainUpdateToggle = !knowledgeBaseStore.mainUpdateToggle;
  knowledgeBaseStore.sidebarUpdateToggle =
    !knowledgeBaseStore.sidebarUpdateToggle;
});

const ADD_DOCUMENT_MENU_OPTIONS: MenuOption[] = [
  {
    text: "Создать папку",
    icon: getDocumentIcon(DocumentType.Folder),
    callback: () => createDocumentInput(DocumentType.Folder),
  },
  {
    text: "Создать документ",
    icon: getDocumentIcon(DocumentType.Article),
    callback: () => createDocumentInput(DocumentType.Article),
  },
  {
    text: "Создать ссылку",
    icon: getDocumentIcon(DocumentType.Link),
    callback: () => createDocumentInput(DocumentType.Link),
  },
];

const createDocumentInput = (docType: DocumentType) => {
  sidebarDocuments.value.unshift({
    title: getNewTitle(docType),
    documentType: docType,
    id: Math.random(),
    createdAt: "",
    updatedAt: "",
    author: "Матвей",
    subDocuments: [],
  });

  nextTick(() => {
    const input = document.querySelector(".doc-input") as HTMLInputElement;

    if (input) {
      input.select();
    }
  });
};

const handleBackClick = () => {
  sidebarStore.goBack();
};

const routes = [
  {
    title: "База знаний",
    icon: notebookOpenIcon,
    to: {
      path: `/${Route.KNOWLEDGE_BASE}`,
    },
  },
  {
    title: "Тестирования",
    icon: notebookIcon,
    to: {
      path: `/${Route.KNOWLEDGE_BASE_TESTING}`,
      query: {
        tab: TestingTab.TESTS_LIST,
        page: 1,
        count: 10,
        search: "",
      },
    },
  },
  {
    title: "Опросы",
    icon: notebookNotifyIcon,
    to: {
      path: `/${Route.KNOWLEDGE_BASE_SURVEY}`,
      query: {
        page: 1,
        count: 10,
        search: "",
      },
    },
  },
  {
    title: "Найм сотрудников",
    icon: userIcon,
    to: {
      path: `/${Route.HIRING}`,
      // query: {
      //   page: 1,
      //   count: 10,
      //   search: "",
      // },
    },
  },
];
</script>

<style lang="scss" scoped></style>
