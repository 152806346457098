/*
Каждому сайдбару свой тип, у дефолтного садйбара нет типа
 */

export enum SidebarType {
  KNOWLEDGE_BASE = "knowledge-base",
  KNOWLEDGE_BASE_TESTING_CREATE = "knowledge-base-testing-create",
  KNOWLEDGE_BASE_TESTING_EDIT = "knowledge-base-testing-edit",
  KNOWLEDGE_BASE_TESTING_TEMPLATE_CREATE = "knowledge-base-testing-template-create",
  KNOWLEDGE_BASE_TESTING_TEMPLATE_EDIT = "knowledge-base-testing-template-edit",
  KNOWLEDGE_BASE_SURVEY_CREATE = "knowledge-base-survey-create",
  KNOWLEDGE_BASE_SURVEY_TEMPLATE_CREATE = "knowledge-base-survey-template-create",
  KNOWLEDGE_BASE_SURVEY_TEMPLATE_EDIT = "knowledge-base-survey-template-edit",
  KNOWLEDGE_BASE_SURVEY_EDIT = "knowledge-base-survey-edit",
  USER_CABINET = "user-cabinet",
  NONE = "",
}
