<template>
  <div
    :class="[
      {
        'pointer-events-none cursor-not-allowed': item.isLocked,
        '!bg-background': activeItemId === item.id,
        'hover:bg-background': itemPosition !== 'sidebar',
      },
      `h-[${itemHeight}px]`,
    ]"
    class="navigation-item group items-center px-4 py-1 duration-200"
    v-bind="$attrs"
  >
    <div
      v-if="item.createdAt"
      :class="{ 'is-last-item': isLastItem }"
      class="navigation-item--inner flex w-full flex-col gap-2"
    >
      <div
        class="navigation-item--content flex min-h-8 w-full items-center gap-2"
      >
        <img
          v-if="item.subDocuments?.length"
          :class="{
            '!img--primary !rotate-0': model.includes(item.id),
          }"
          alt=""
          class="navigation-item--toggle img--hoar flex h-[18px] w-[18px] shrink-0 -rotate-90 cursor-pointer duration-200"
          src="@/assets/icons/direction/arrow-circle.svg"
          @click.prevent="handleCollapseIconClick(item.id)"
        />
        <div class="navigation-item--rows flex flex-col gap-2 truncate">
          <div class="flex items-center gap-2">
            <div
              v-if="!item.isTest"
              class="flex h-[18px] w-[18px] items-center justify-center"
            >
              <img
                v-if="item.documentType !== DocumentType.Link"
                :alt="item.title"
                :src="getDocumentIcon(item.documentType)"
                class="img--hoar flex h-4 min-h-4 w-4 min-w-4 shrink-0"
              />
              <app-popover v-else placement="bottom-start">
                <button>
                  <img
                    :alt="item.title"
                    :class="{ '!img--primary': Boolean(item.link) }"
                    :src="getDocumentIcon(item.documentType)"
                    class="img--hoar group-hover:img--primary flex h-4 w-4 shrink-0"
                  />
                </button>
                <template #content="{ close }">
                  <document-link-edit
                    :link="item.link"
                    @delete="
                      () => {
                        close();
                        deleteLink();
                      }
                    "
                    @submit="
                      (link: string) => {
                        close();
                        updateLink(link);
                      }
                    "
                  />
                </template>
              </app-popover>
            </div>
            <div
              v-if="item.isTest"
              class="flex h-[19px] items-center justify-center rounded-full bg-[#7D64FF] px-2 text-11 font-semibold text-white"
            >
              Тест
            </div>
            <router-link
              v-if="item.documentType === DocumentType.Article && !isEdit"
              :to="{
                path: `/${Route.KNOWLEDGE_BASE_ARTICLE}/${item.id}`,
                query: {
                  mode: item.hasContent ? ArticleMode.WATCH : ArticleMode.EDIT,
                },
              }"
              class="whitespace-nowrap text-14 font-medium leading-5"
              >{{ item.title }}
            </router-link>
            <div v-if="item.documentType === DocumentType.Link && !isEdit">
              <a
                v-if="!isEdit"
                :class="{
                  'pointer-events-none': !item.link,
                  'text-primary': item.link,
                }"
                :href="item.link"
                class="text-14 font-medium leading-5 group-hover:text-primary"
                target="_blank"
                >{{ item.title }}
              </a>
            </div>
            <div
              v-if="
                (item.documentType === DocumentType.Folder ||
                  item.documentType === DocumentType.Test) &&
                !isEdit
              "
            >
              <span class="whitespace-nowrap text-14 font-medium leading-5"
                >{{ item.title }}
              </span>
            </div>
            <div v-if="isEdit" class="relative flex flex-shrink items-center">
              <input
                v-model="item.title"
                :disabled="isLoading"
                :size="item.title.length ? item.title.length + 3 : 1"
                class="edit-input h-[26px] rounded-[6px] border border-primary px-2 py-0.5 text-13 font-medium"
                @blur="handleDocInputBlur"
                @keyup.enter="handleDocInputEnter"
              />
              <img
                v-if="isLoading"
                alt="Загрузка"
                class="absolute right-3 top-[calc(50%-7px)] h-[14px] w-[14px] animate-spin"
                src="@/assets/icons/loaders/primary-loader.svg"
              />
            </div>
            <div
              v-if="
                item.status === 'draft' &&
                item.documentType === DocumentType.Article &&
                itemPosition === 'content'
              "
              class="flex h-[19px] items-center justify-center rounded-full bg-hoar/10 px-2 text-11 font-semibold text-hoar"
            >
              Черновик
            </div>

            <slot :item="item" name="locked" />
          </div>
          <slot :item="item" name="item" />
        </div>
        <div class="ml-auto flex items-center gap-5">
          <template v-if="!noEdit">
            <app-menu
              v-if="itemPosition === 'sidebar' && !item.price"
              :options="currentMenuOptions"
              list-styles="w-[216px]"
              placement="left-start"
              @update:model-value="handlePopupUpdate"
            >
              <button
                :class="{ '!opacity-100': activeItemId === item.id }"
                class="flex h-8 w-8 items-center justify-center rounded-2 opacity-0 group-hover:opacity-100 hover:bg-hoar/10 active:bg-hoar/15"
              >
                <img
                  alt="..."
                  class="img--hoar"
                  src="@/assets/icons/dots.svg"
                />
              </button>
            </app-menu>
            <app-menu
              v-else-if="!item.price || isTemplate"
              :options="currentMenuOptions"
              list-styles="w-[216px]"
              placement="left-start"
              @update:model-value="handlePopupUpdate"
            >
              <button
                :class="{ '!opacity-100': activeItemId === item.id }"
                class="img--hoar hover:img--primary opacity-0 group-hover:opacity-100 active:scale-90"
              >
                <img alt="..." src="@/assets/icons/dots.svg" />
              </button>
            </app-menu>
          </template>
          <slot :item="item" name="settings" />
        </div>
      </div>
      <span
        v-if="level > 1"
        :class="{ hidden: isLastItem }"
        :style="{
          left: initialOffset + (level - 2) * itemLeftOffset + 18 / 2 + 'px',
          height: itemHeight / 3 + 'px',
        }"
        class="navigation-item--stick"
      ></span>
    </div>
    <div v-else class="relative flex items-center">
      <input
        v-model="item.title"
        :disabled="isLoading"
        class="doc-input h-[26px] w-full rounded-[6px] border border-primary px-2 py-0.5 text-13 font-medium"
        @blur="handleDocInputBlur"
        @keyup.enter="handleDocInputEnter"
      />
      <img
        v-if="isLoading"
        alt="Загрузка"
        class="absolute right-3 top-[calc(50%-7px)] h-[14px] w-[14px] animate-spin"
        src="@/assets/icons/loaders/primary-loader.svg"
      />
    </div>
  </div>
  <collapse
    :class="{
      '!h-auto !overflow-visible': model.includes(item.id),
    }"
    :when="Boolean(item.subDocuments?.length && model.includes(item.id))"
    class="navigation-item--collapse"
  >
    <div class="flex flex-col">
      <knowledge-base-navigation-item
        v-for="(subDocument, idx) in item.subDocuments"
        :key="subDocument.id"
        v-model="model"
        :class="{ 'hover:bg-background': itemPosition === 'content' }"
        :initial-offset="initialOffset"
        :is-last-item="
          idx === item.subDocuments?.length - 1
            ? !model.includes(subDocument.id)
            : false
        "
        :is-template="isTemplate"
        :item="subDocument"
        :item-height="itemHeight"
        :item-position="itemPosition"
        :itemLeftOffset="itemLeftOffset"
        :items="items"
        :level="level + 1"
        :style="{
          paddingLeft: initialOffset + level * itemLeftOffset + 'px',
        }"
        :subItems="item.subDocuments"
      >
        <template #item="{ item }">
          <slot :item="item" name="item" />
        </template>
        <template #settings="{ item }">
          <slot :item="item" name="settings" />
        </template>
        <template v-if="$slots.locked" #locked="{ item }">
          <slot :item="item" name="locked" />
        </template>
      </knowledge-base-navigation-item>
    </div>
    <span
      :style="{
        left: initialOffset + (level - 1) * itemLeftOffset + 18 / 2 + 'px',
        // height: `calc(100% - ${itemHeight / 2}px)`,
        height:
          item.subDocuments?.length !== 1
            ? `calc(100% - ${itemHeight / 2}px)`
            : `${itemHeight / 2}px`,
        // height: `${itemHeight * item.subDocuments?.length - itemHeight / 2}px`,
      }"
      class="navigation-item--column"
    ></span>
  </collapse>
</template>

<script lang="ts" setup>
import { Collapse } from "vue-collapsed";
import { Route } from "@/router/router.types";
import { ArticleMode } from "@/views/knowledge-base/data";
import {
  type DocumentTree,
  DocumentType,
} from "@boostbiz/wiki_client/schemas/documents/documents.schema";
import { getDocumentIcon, getNewTitle } from "@/views/knowledge-base/utils";
import type { MenuOption } from "@/shared/UIKit/app-menu/app-menu.types";
import { computed, nextTick, ref } from "vue";
import trashIcon from "@/assets/icons/trash-empty.svg";
import gearIcon from "@/views/knowledge-base/icons/knowledge-gear.svg";
import editIcon from "@/assets/icons/edit/edit-line.svg";
import { useKnowledgeBaseStore } from "@/stores/knowledge-base/knowledge-base";
import { useDeleteDialogStore } from "@/stores/delete-dialog";
import DocumentLinkEdit from "@/views/knowledge-base/components/knowledge-base-navigation/document-link-edit.vue";
import AppMenu from "@/shared/UIKit/app-menu/app-menu.vue";
import { wikiApi } from "@/api";
import AppPopover from "@/shared/UIKit/app-popover.vue";

interface Props {
  item: DocumentTree;
  level?: number;
  isLastItem?: boolean;
  initialOffset?: number;
  itemHeight?: number;
  itemLeftOffset?: number;
  itemPosition?: "sidebar" | "content";
  isTemplate?: boolean;
  selectable?: boolean;
  noEdit?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  level: 1,
  isLastItem: false,
  initialOffset: 16,
  itemPosition: "sidebar",
  itemHeight: 40,
  itemLeftOffset: 26,
  isTemplate: false,
  selectable: false,
  noEdit: false,
});
const emit = defineEmits<{
  delete: [];
}>();

const knowledgeBaseStore = useKnowledgeBaseStore();
const deleteDialogStore = useDeleteDialogStore();
const model = defineModel<string[]>({ required: true });
const modelItems = defineModel<DocumentTree[]>("items", { required: true });
const isLoading = ref(false);
const isEdit = ref(false);
const oldTitle = ref("");
const activeItemId = ref("");

const handlePopupUpdate = (val: boolean) => {
  val ? (activeItemId.value = props.item.id) : (activeItemId.value = "");
};

const handleCollapseIconClick = (itemId: string) => {
  if (model.value.includes(itemId)) {
    model.value = model.value.filter((id) => id !== itemId);
  } else {
    model.value.push(itemId);
  }
};

const deleteLink = async () => {
  await wikiApi.removeLinkFromDocument(props.item.id);
  await knowledgeBaseStore.fetchDocumentTree();
  props.item.link = "";

  if (props.itemPosition === "sidebar") {
    knowledgeBaseStore.sidebarUpdateToggle =
      !knowledgeBaseStore.sidebarUpdateToggle;
  } else {
    knowledgeBaseStore.mainUpdateToggle = !knowledgeBaseStore.mainUpdateToggle;
  }
};

const showDocumentTitleInput = (docType: DocumentType) => {
  model.value.push(props.item.id);

  props.item.subDocuments.unshift({
    title: getNewTitle(docType),
    documentType: docType,
    id: `${props.item.subDocuments.length + props.item.subDocuments.length}`,
    createdAt: "",
    updatedAt: "",
    parentId: `${props.item.id}`,
    author: "Матвей",
  });

  nextTick(() => {
    const input = document.querySelector(".doc-input") as HTMLInputElement;

    if (input) {
      input.select();
    }
  });
};

const ADD_DOCUMENT_MENU_OPTIONS: MenuOption[] = [
  {
    text: "Создать подпапку",
    icon: getDocumentIcon(DocumentType.Folder),
    callback: () => showDocumentTitleInput(DocumentType.Folder),
  },
  {
    text: "Создать документ",
    icon: getDocumentIcon(DocumentType.Article),
    callback: () => showDocumentTitleInput(DocumentType.Article),
    isDivided: props.isTemplate,
  },
  {
    text: "Создать ссылку",
    icon: getDocumentIcon(DocumentType.Link),
    callback: () => showDocumentTitleInput(DocumentType.Link),
    isDivided: props.item.documentType === DocumentType.Folder,
  },
];

const EDIT_DOCUMENT_MENU_OPTIONS: MenuOption[] = [
  {
    text: "Редактировать название",
    icon: editIcon,
    callback: () => {
      isEdit.value = true;
      oldTitle.value = props.item.title;

      nextTick(() => {
        const input = document.querySelector(".edit-input") as HTMLInputElement;

        if (input) {
          input.select();
        }
      });
    },
  },
  {
    text: "Изменить доступы",
    icon: gearIcon,
    callback: () => {},
  },
  {
    text: "Удалить",
    icon: trashIcon,
    callback: async () => {
      deleteDialogStore.confirm("документ", async (callback: any) => {
        const isDeleteConfirmed = await callback;

        if (isDeleteConfirmed) {
          try {
            if (props.isTemplate) {
              await wikiApi.deleteTemplate(props.item.id);
              modelItems.value = await wikiApi.getTemplatesTree();
              await knowledgeBaseStore.fetchDocumentTree();
              knowledgeBaseStore.mainUpdateToggle =
                !knowledgeBaseStore.mainUpdateToggle;
            } else {
              await wikiApi.deleteDocument(props.item.id);
              await knowledgeBaseStore.fetchDocumentTree();

              knowledgeBaseStore.mainUpdateToggle =
                !knowledgeBaseStore.mainUpdateToggle;
              knowledgeBaseStore.sidebarUpdateToggle =
                !knowledgeBaseStore.sidebarUpdateToggle;
            }

            if (knowledgeBaseStore.search) {
              modelItems.value = await knowledgeBaseStore.searchDocuments();
            }
          } catch (e) {}
        }
      });
    },
  },
];

const currentMenuOptions = computed((): MenuOption[] => {
  if (props.item.documentType === DocumentType.Folder) {
    if (props.isTemplate) {
      return ADD_DOCUMENT_MENU_OPTIONS.filter(
        (item) => item.text !== "Создать ссылку",
      ).concat(EDIT_DOCUMENT_MENU_OPTIONS);
    }
    return ADD_DOCUMENT_MENU_OPTIONS.concat(EDIT_DOCUMENT_MENU_OPTIONS);
  }

  return EDIT_DOCUMENT_MENU_OPTIONS;
});

const createDocument = async () => {
  if (!props.item.title) {
    if (!props.item.subDocuments.length) {
      modelItems.value.shift();

      return;
    }

    props.item.subDocuments.shift();

    return;
  }

  isLoading.value = true;

  if (!props.isTemplate) {
    const data = await wikiApi.createDocument({
      title: props.item.title,
      documentType: props.item.documentType,
      parentId: props.item.parentId,
      author: props.item.author,
    });

    props.item.id = data.id;
    props.item.title = data.title;
    props.item.createdAt = data.createdAt;
    props.item.link = data.link;
    props.item.subDocuments = [];

    await knowledgeBaseStore.fetchDocumentTree();
  } else {
    const data = await wikiApi.createTemplate({
      title: props.item.title,
      documentType: props.item.documentType,
      parentId: props.item.parentId,
      author: props.item.author,
    });

    props.item.id = data.id;
    props.item.title = data.title;
    props.item.createdAt = data.createdAt;
    props.item.link = data.link;
    props.item.price = 0;
    props.item.subDocuments = [];

    modelItems.value = await wikiApi.getTemplatesTree();
  }

  knowledgeBaseStore.sidebarUpdateToggle =
    !knowledgeBaseStore.sidebarUpdateToggle;
  knowledgeBaseStore.mainUpdateToggle = !knowledgeBaseStore.mainUpdateToggle;

  isLoading.value = false;
};

const updateLink = async (link: string) => {
  const data = await wikiApi.updateDocument(props.item.id, {
    link,
    documentType: DocumentType.Link,
    author: "Матвей",
  });

  await knowledgeBaseStore.fetchDocumentTree();

  if (props.itemPosition === "sidebar") {
    knowledgeBaseStore.sidebarUpdateToggle =
      !knowledgeBaseStore.sidebarUpdateToggle;
  } else {
    knowledgeBaseStore.mainUpdateToggle = !knowledgeBaseStore.mainUpdateToggle;
  }

  props.item.link = data.link;
  props.item.updatedAt = data.updatedAt;
};

const updateDocument = async () => {
  if (!props.item.title || props.item.title === oldTitle.value) {
    props.item.title = oldTitle.value;
    isEdit.value = false;

    return;
  }

  isLoading.value = true;

  if (!props.isTemplate) {
    const data = await wikiApi.updateDocument(props.item.id, {
      author: "Матвей",
      title: props.item.title,
      documentType: props.item.documentType,
    });

    await knowledgeBaseStore.fetchDocumentTree();

    if (props.itemPosition === "sidebar") {
      knowledgeBaseStore.sidebarUpdateToggle =
        !knowledgeBaseStore.sidebarUpdateToggle;
    } else {
      knowledgeBaseStore.mainUpdateToggle =
        !knowledgeBaseStore.mainUpdateToggle;
    }

    props.item.title = data.title;
    props.item.updatedAt = data.updatedAt;
  } else {
    const data = await wikiApi.updateTemplate(props.item.id, {
      author: "Матвей",
      title: props.item.title,
      documentType: props.item.documentType,
    });

    props.item.title = data.title;
    props.item.updatedAt = data.updatedAt;

    knowledgeBaseStore.mainUpdateToggle = !knowledgeBaseStore.mainUpdateToggle;
  }

  isLoading.value = false;
  isEdit.value = false;
};

const handleDocInputBlur = (evt) => {
  if (isEdit.value) {
    updateDocument();
  } else {
    createDocument();
  }
};

const handleDocInputEnter = (evt) => {
  evt.target.blur();
};
</script>

<style lang="scss" scoped>
.navigation-item {
  @apply relative;
  &--inner {
    .navigation-item--stick {
      @apply absolute top-1/2 w-2 -translate-y-full border-b border-l border-lunar;
    }

    &.is-last-item {
      .navigation-item--stick {
        @apply rounded-bl-1;
      }
    }
  }

  &--collapse {
    @apply relative;
    //height: auto !important;

    .navigation-item--column {
      @apply absolute top-0 w-2 overflow-x-visible rounded-bl-1 border-b border-l border-lunar;
    }

    &[data-collapse="expanding"] {
      height: 100% !important;
      overflow: visible !important;
    }
  }
}
</style>
