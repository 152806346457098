<template>
  <div>
    <button
      ref="referenceRef"
      :class="{ 'pointer-events-none': disabled }"
      class="flex items-center justify-center"
      v-bind="$attrs"
      @click="model = true"
    >
      <slot />
    </button>
    <transition name="fade">
      <div v-if="model" ref="floatingRef" :style="floatingStyles" class="z-10">
        <slot :close="() => (model = false)" :is-open="model" name="content" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import {
  autoUpdate,
  flip,
  offset,
  type Placement,
  shift,
  useFloating,
} from "@floating-ui/vue";
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";

interface Props {
  placement?: Placement;
  offset?: string;
  disabled?: boolean;
  arrow?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  placement: "right",
  offset: "12",
  disabled: false,
  arrow: false,
});

const model = defineModel<boolean>({ default: false });
const referenceRef = ref(null);
const floatingRef = ref(null);
const { floatingStyles } = useFloating(referenceRef, floatingRef, {
  placement: props.placement,
  middleware: [offset(3), flip(), shift()],
  whileElementsMounted(...args) {
    return autoUpdate(...args, { animationFrame: true });
  },
});

onClickOutside(floatingRef, (evt) => {
  if (referenceRef.value && !referenceRef.value.contains(evt.target)) {
    model.value = false;
  }
});
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
