<template>
  <app-popover arrow placement="bottom">
    <template #default>
      <app-button
        class="flex h-9 items-center gap-1.5 !pl-1 !pr-1.5"
        type="texted"
        variant="tertiary"
      >
        <div
          :style="{ backgroundColor: selectedProject?.color }"
          class="flex h-7 w-7 items-center justify-center rounded-[10px]"
        >
          <p class="text-14 font-semibold uppercase text-white">
            {{ selectedProject?.title[0] }}
          </p>
        </div>
        <p class="text-13 font-semibold">
          {{ selectedProject?.title }}
        </p>
        <img
          alt="^"
          class="app-select-arrow img--hoar h-[14px] w-[14px]"
          src="@/assets/icons/direction/arrow-bottom-simple.svg"
        />
      </app-button>
    </template>
    <template #content="{ close }">
      <div
        class="flex w-[260px] flex-col overflow-hidden rounded-[10px] border border-solid border-lunar bg-white"
      >
        <div class="flex flex-col px-1.5 py-[5px]">
          <app-button
            v-for="option in options"
            :key="option.value"
            class="flex h-9 w-full items-center gap-2 rounded-2 !pl-1.5 !pr-2.5 hover:bg-background"
            type="texted"
            variant="tertiary"
            @click="handleSelectionChange(option.value, close)"
          >
            <div
              :style="{ backgroundColor: option.color }"
              class="flex h-7 w-7 items-center justify-center rounded-[10px]"
            >
              <p class="text-14 font-semibold uppercase text-white">
                {{ option.title[0] }}
              </p>
            </div>
            <span class="mr-1.5 text-13 font-medium text-base">
              {{ option.title }}
            </span>
            <div class="ml-auto h-[18px] w-[18px]">
              <img
                v-show="selectedProject.value === option.value"
                alt="Выбрано"
                class="img--primary"
                src="@/assets/icons/tick/tick-circle-filled.svg"
              />
            </div>
          </app-button>
        </div>

        <div
          class="sticky -bottom-1 h-11 w-full border-t border-lunar bg-white p-2"
        >
          <app-button
            class="w-full whitespace-nowrap"
            size="tiny"
            type="texted"
            @click="handleAddProject(close)"
            >Добавить проект
          </app-button>
        </div>
      </div>
    </template>
  </app-popover>
</template>

<script lang="ts" setup>
import AppButton from "@/shared/UIKit/app-button/app-button.vue";
import type { IOption } from "@/shared/UIKit/app-select/app-select.types";
import AppPopover from "@/shared/UIKit/app-popover.vue";

const props = defineProps<{
  options: IOption[];
  selectedProject: IOption | {};
}>();

const emit = defineEmits<{
  "add-project": [];
  change: [string];
}>();

const handleAddProject = (closeDropdown: () => void) => {
  emit("add-project");
  closeDropdown();
};

const handleSelectionChange = (val: string, closeDropdown: () => void) => {
  emit("change", val);
  closeDropdown();
};
</script>

<style lang="scss" scoped></style>
