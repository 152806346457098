<template>
  <navigation-layout
    :class="{ '!translate-x-0': isSidebarOpened }"
    :level="2"
    class="translate-x-full duration-200"
    is-sub-layout
    title="Редактирование шаблона опроса"
  >
    <template #header>
      <button @click="handleBackClick">
        <img
          alt=""
          class="img--hoar absolute left-4 top-1/2 h-5 w-5 -translate-y-1/2 cursor-pointer"
          src="@/assets/icons/direction/arrow-right-long.svg"
        />
      </button>
    </template>

    <template #default>
      <div class="m-1.5">
        <settings-questions-tabs />
      </div>
      <div class="grid grid-cols-2 gap-1.5 border-y border-y-lunar p-1.5">
        <action-button
          v-for="action in EDIT_TEMPLATE_ACTIONS"
          :key="action.value"
          :action="action"
          :active="action.value === EditAction.EDIT"
          :disabled="isPreviewLoading || isEditLoading"
          :loading="resolveCurrentActionLoading(action.value as EditAction)"
          @click="resolveCurrentAction(action.value as EditAction)"
        />
      </div>
    </template>
  </navigation-layout>
</template>
<script lang="ts" setup>
import { computed, ref } from "vue";
import NavigationLayout from "@/layouts/navigation/navigation-layout.vue";
import { useSidebarStore } from "@/stores/sidebar/sidebar";
import { storeToRefs } from "pinia";
import { SidebarType } from "@/layouts/sidebars/sidebars.types";
import SettingsQuestionsTabs from "@/layouts/sidebars/knowledge-base-create-sidebar/settings-questions-tabs.vue";
import {
  EDIT_TEMPLATE_ACTIONS,
  EditAction,
} from "@/layouts/sidebars/knowledge-base-create-sidebar/knowledge-testing-survey-sidebar.data";
import ActionButton from "@/modules/knowledge-base/views/testing-survey/components/action-button.vue";
import { useKnowledgeBaseStore } from "@/stores/knowledge-base/knowledge-base";

import { surveyApi } from "@/api";
import { useSnackbarStore } from "@/stores/snackbar/snackbar";
import { Route } from "@/router/router.types";
import { useRouter } from "vue-router";
import type { QuestionFormDTO } from "@boostbiz/question_form_microservice/client/schemas";
import { SnackbarStatus } from "@/stores/snackbar/snackbar.types";

const router = useRouter();

const { showSnackbar } = useSnackbarStore();

const sidebarStore = useSidebarStore();
const knowledgeBaseStore = useKnowledgeBaseStore();

const { currentSidebars } = storeToRefs(sidebarStore);

const isSidebarOpened = computed(
  () =>
    currentSidebars.value[currentSidebars.value.length - 1] ===
    SidebarType.KNOWLEDGE_BASE_SURVEY_TEMPLATE_EDIT,
);

const handleBackClick = () => {
  sidebarStore.goBack();
};

const isEditLoading = ref(false);
const isPreviewLoading = ref(false);

const resolveCurrentActionLoading = (action: EditAction) => {
  switch (action) {
    case EditAction.EDIT:
      return isEditLoading.value;
    case EditAction.PREVIEW:
      return isPreviewLoading.value;
  }
};

const editSurvey = async () => {
  if (
    knowledgeBaseStore.surveyTemplateFormRef!.validate() &&
    knowledgeBaseStore.surveyTemplateQuestionsRef!.validate()
  ) {
    isEditLoading.value = true;

    try {
      const { _id: id, ...surveyTemplate } = knowledgeBaseStore.surveyTemplate;
      await surveyApi.templates.patchTemplate(id, surveyTemplate);

      showSnackbar("Шаблон опрос успешно отредактирован");
      router.push({
        path: `/${Route.KNOWLEDGE_BASE_SURVEY_TEMPLATES}`,
        query: {
          page: 1,
          count: 10,
          search: "",
        },
      })
    } catch (e) {
      showSnackbar("Не удалось изменить шаблон опрос", SnackbarStatus.ERROR);
      console.error(e);
    } finally {
      isEditLoading.value = false;
    }
  }
};

const previewSurvey = async () => {
  isPreviewLoading.value = true;
  knowledgeBaseStore.surveyPreview =
    knowledgeBaseStore.surveyTemplate as unknown as QuestionFormDTO;

  await router.push(`/${Route.KNOWLEDGE_BASE_SURVEY_PREVIEW}`);
  isPreviewLoading.value = false;
};

const resolveCurrentAction = (action: EditAction) => {
  switch (action) {
    case EditAction.EDIT:
      return editSurvey();
    case EditAction.PREVIEW:
      return previewSurvey();
  }
};
</script>

<style lang="scss" scoped></style>
