<template>
  <app-dialog v-model="balanceDialogStore.isOpen" width="400">
    <template #header> Пополнение баланса</template>
    <app-input v-model="sum" label="Сумма пополнения" type="number" />
    <template #footer>
      <app-button
        :loading="isLoading"
        class="w-full"
        type="texted"
        variant="primary"
        @click="handleTopUpBalanceBtnClick"
      >
        Пополнить
      </app-button>
    </template>
  </app-dialog>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import AppDialog from "@/shared/UIKit/app-dialog/app-dialog.vue";
import AppButton from "@/shared/UIKit/app-button/app-button.vue";
import AppInput from "@/shared/UIKit/app-input.vue";
import { paymentApi } from "@/api";
import { useBalanceDialogStore } from "@/stores/dialogs/balance-dialog";

const sum = ref(0);
const isLoading = ref(false);
const balanceDialogStore = useBalanceDialogStore();

const handleTopUpBalanceBtnClick = async () => {
  isLoading.value = true;

  const response = await paymentApi.initTinkoffPayment({ amount: sum.value });
  window.open(response.paymentUrl, "blank");

  isLoading.value = false;
  balanceDialogStore.isOpen = false;
};
</script>

<style lang="scss" scoped></style>
