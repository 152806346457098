<template>
  <app-dialog
    v-model="model"
    :permanent="
      !projectsStore.projects.length &&
      !projectsStore.isLoadingProjects &&
      Boolean(userStore.user)
    "
    width="900"
  >
    <template #header> Создайте проект</template>
    <Form
      v-slot="{ isSubmitting, meta }"
      :validation-schema="schema"
      class="flex gap-4"
      @submit="handleSubmit"
    >
      <div class="-mb-4 -mt-4 basis-2/3 border-r border-grizzle">
        <div class="pb-4 pr-6 pt-4">
          <app-input
            ref="inputRef"
            v-model="projectName"
            class="mb-5"
            name="create"
            placeholder="Введите название проекта"
            size="small"
          />

          <div class="-ml-6 -mr-6 border-b border-t border-lunar">
            <div
              class="flex h-8 items-center pl-6 text-12 font-semibold text-hoar"
            >
              Готовые шаблоны проектов
            </div>
          </div>

          <div class="flex flex-col">
            <div
              v-for="template in TEMPLATES"
              class="flex h-[42px] items-center"
            >
              <app-radio
                v-model="selectedTemplate"
                :item="template"
                class="w-full"
              >
                <div class="flex justify-between">
                  <div class="flex gap-2">
                    <img
                      alt=""
                      class="img--hoar"
                      src="@/assets/icons/file-text.svg"
                    />
                    <p class="text-13 font-semibold">{{ template.text }}</p>
                  </div>
                  <p class="text-12 font-medium text-hoar">30.05.24</p>
                </div>
              </app-radio>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-grow flex-col justify-between">
        <ul class="flex flex-col gap-2">
          <li class="flex items-start gap-3 rounded-[10px] bg-background p-3">
            <img
              alt=""
              class="img--primary"
              src="@/assets/icons/transmission.svg"
            />
            <div class="flex flex-col gap-[6px]">
              <p class="text-13 font-semibold">Оргструктура:</p>
              <p class="text-12 font-medium">от 15 сотрудников с метриками</p>
            </div>
          </li>

          <li class="flex items-start gap-3 rounded-[10px] bg-background p-3">
            <img alt="" class="img--primary" src="@/assets/icons/routing.svg" />
            <div class="flex flex-col gap-[6px]">
              <p class="text-13 font-semibold">Бизнес-процессы:</p>
              <p class="text-12 font-medium">20 шт.</p>
            </div>
          </li>

          <li class="flex items-start gap-3 rounded-[10px] bg-background p-3">
            <img
              alt=""
              class="img--primary"
              src="@/assets/icons/notebook-open.svg"
            />
            <div class="flex flex-col gap-[6px]">
              <p class="text-13 font-semibold">База знаний:</p>
              <p class="text-12 font-medium">30 документов и 10 тестирований</p>
            </div>
          </li>
        </ul>

        <div class="flex w-full flex-col gap-1">
          <app-button class="w-full" size="medium">Применить</app-button>
          <app-button
            :loading="isSubmitting && meta.valid"
            action="submit"
            class="w-full"
            size="medium"
            type="texted"
            variant="tertiary"
            >Создать без шаблона
          </app-button>
        </div>
      </div>
    </Form>
  </app-dialog>
</template>

<script lang="ts" setup>
import AppDialog from "@/shared/UIKit/app-dialog/app-dialog.vue";
import { ref, watch } from "vue";
import AppInput from "@/shared/UIKit/app-input.vue";
import { Form } from "vee-validate";
import { useProjectsStore } from "@/stores/projects/projects";
import * as Yup from "yup";
import AppRadio from "@/shared/UIKit/app-radio.vue";
import AppButton from "@/shared/UIKit/app-button/app-button.vue";
import { useUserStore } from "@/stores/user/user";

const projectsStore = useProjectsStore();
const userStore = useUserStore();

const projectName = ref("");
const inputRef = ref();
const selectedTemplate = ref("");
const model = defineModel<boolean>({ required: true });
const schema = Yup.object().shape({
  create: Yup.string().min(
    5,
    "Название проекта должно содержать не менее 5 символов",
  ),
});

watch(
  () => model.value,
  (val) => {
    if (!val) {
      projectName.value = "";
    }
  },
);

const handleSubmit = async () => {
  try {
    await projectsStore.createProject(projectName.value);
    projectName.value = "";
  } catch (e) {}
  model.value = false;
};

const TEMPLATES = [
  { text: "IT компания", value: "Регламент созвонов отдела" },
  {
    text: "Маркетплейсы",
    value: "Создание онбординга для нового сотрудника",
  },
  { text: "Логистика", value: "Шаблон создания регламентов" },
  { text: "HR продукт", value: "Шаблон контент - план" },
  {
    text: "Консалтинг",
    value: "Шаблон партнерского соглашения",
  },
  {
    text: "Бухгалтерские услуги",
    value: "Регламент по созданию нового отдела компании",
  },
  { text: "Инфобизнес", value: "Шаблон Job Offer" },
];
</script>

<style lang="scss" scoped></style>
