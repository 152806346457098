import accessesIcon from "@/assets/icons/navigation/accesses.svg";
import employeesIcon from "@/assets/icons/navigation/employees.svg";
import businessProcessesIcon from "@/assets/icons/navigation/business-processes.svg";
import gradingIcon from "@/assets/icons/navigation/grading.svg";
import orgStructureIcon from "@/assets/icons/navigation/org-structure.svg";
import knowledgeBaseIcon from "@/assets/icons/navigation/knowledge-base.svg";
import workBoardIcon from "@/assets/icons/navigation/work-board.svg";
import workflowIcon from "@/assets/icons/navigation/workflow.svg";
import templatesIcon from "@/assets/icons/navigation/templates.svg";
import projectIcon from "@/assets/icons/project.svg";
import { Route } from "@/router/router.types";
import { SidebarType } from "@/layouts/sidebars/sidebars.types";
import { OrgTemplatesTab } from "@/views/org-structure-templates/data";
import type { RouteLocationRaw } from "vue-router";
import userCircle from "@/assets/icons/user/user-circle.svg";

export interface NavigationItem {
  title: string;
  icon: string;
  link?: RouteLocationRaw;
  subItems?: NavigationItem[];
  sidebar?: SidebarType;
  type?: "primary";
}

export const NAVIGATION_ITEMS: NavigationItem[] = [
  {
    title: "Рабочий стол",
    icon: workBoardIcon,
  },
  {
    title: "Оргструктура",
    icon: orgStructureIcon,
    subItems: [
      {
        title: "Рабочая область",
        link: `/${Route.ORG_STRUCTURE}`,

        icon: workflowIcon,
      },

      {
        title: "Шаблоны",
        link: {
          path: `/${Route.ORG_STRUCTURE_TEMPLATES}`,
          query: {
            tab: OrgTemplatesTab.ORG_STRUCTURES,
            search: "",
          },
        },
        icon: templatesIcon,
      },
      {
        title: "Доступы",
        icon: accessesIcon,
      },
      {
        title: "Грейдирование",
        icon: gradingIcon,
      },
      {
        title: "Сотрудники",
        icon: employeesIcon,
      },
    ],
  },
  {
    title: "База знаний",
    icon: knowledgeBaseIcon,
    link: `/${Route.KNOWLEDGE_BASE_ADMIN}/`,
    sidebar: SidebarType.KNOWLEDGE_BASE,
  },
  {
    title: "Бизнес-процессы",
    icon: businessProcessesIcon,
    link: `/${Route.BUSINESS_PROCESS}`,
  },
];

export const USER_CABINET_NAVIGATION_ITEMS: NavigationItem[] = [
  // {
  //   title: "",
  //   icon: userIcon,
  //   link: `/${Route.USER_PROFILE}`,
  // },
  {
    title: "Профиль",
    icon: userCircle,
    link: `/${Route.ADMIN_PROFILE}`,
  },
  {
    title: "Управление проектами",
    icon: projectIcon,
    link: `/${Route.PROJECTS}`,
  },
  // {
  //   title: "База знаний",
  //   icon: knowledgeBaseIcon,
  //   link: `/${Route.KNOWLEDGE_BASE}/`,
  //   sidebar: SidebarType.KNOWLEDGE_BASE,
  // },
  // {
  //   title: "Бизнес-процессы",
  //   icon: businessProcessesIcon,
  //   link: `/${Route.BUSINESS_PROCESS}`,
  // },
];
