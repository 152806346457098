import type { TabItem } from "@/shared/UIKit/app-tabs/app-tabs.types";

export enum OrgTemplatesTab {
  ORG_STRUCTURES = "org-structures",
  POSITIONS = "positions",
}

export const TABS: TabItem[] = [
  {
    text: "Оргструктуры",
    value: OrgTemplatesTab.ORG_STRUCTURES,
  },
  {
    text: "Должности",
    value: OrgTemplatesTab.POSITIONS,
  },
];
