<template>
  <div class="h-screen w-screen">
    <app-header
      v-show="!store.isFullScreen"
      v-model="isNavigationOpen"
      @add-project="isCreateProjectDialogOpen = true"
    />
    <div
      :class="{ 'h-[calc(100vh-52px)] p-5': !store.isFullScreen }"
      class="flex gap-5"
    >
      <app-navigation
        v-if="
          isNavigationOpen &&
          !store.isFullScreen &&
          route.meta.sidebars &&
          !route.meta.sidebars.includes(SidebarType.NONE)
        "
      />
      <div class="flex w-full grow flex-col overflow-hidden">
        <slot />
      </div>
      <app-snackbar />
      <delete-dialog />
      <confirm-dialog />
      <top-up-balance-dialog />
      <create-project-dialog v-model="isCreateProjectDialogOpen" />
      <access-denied-dialog />
      <end-trial-dialog />
    </div>
  </div>
</template>

<script lang="ts" setup>
import AppNavigation from "@/layouts/navigation/app-navigation.vue";
import AppSnackbar from "@/shared/UIKit/app-snackbar.vue";
import DeleteDialog from "@/features/dialogs/delete-dialog.vue";
import ConfirmDialog from "@/layouts/confirm-dialog.vue";
import AppHeader from "@/layouts/headers/app-header.vue";
import { ref, watch } from "vue";
import { useSidebarStore } from "@/stores/sidebar/sidebar";
import CreateProjectDialog from "@/features/dialogs/create-project-dialog.vue";
import { useRoute } from "vue-router";
import { SidebarType } from "@/layouts/sidebars/sidebars.types";
import AccessDeniedDialog from "@/layouts/access-denied-dialog.vue";
import TopUpBalanceDialog from "@/modules/user-cabinet/views/profile/components/dialogs/top-up-balance-dialog.vue";
import EndTrialDialog from "@/features/dialogs/end-trial-dialog.vue";

const isCreateProjectDialogOpen = ref(false);
const store = useSidebarStore();
const route = useRoute();

// НЕ МЕНЯТЬ НА FALSE!
const isNavigationOpen = ref(true);

watch(
  () => route.path,
  (val) => {
    if (val.includes("edit") || val.includes("create")) {
      isNavigationOpen.value = true;
    }
  },
);
</script>

<style lang="scss" scoped></style>
