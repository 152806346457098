<template>
  <app-dialog :permanent="isOpen" :width="userStore.user?.owner ? 400 : 330">
    <div class="flex flex-col items-center">
      <img
        alt=""
        class="img--primary mb-4 h-11 w-11"
        src="@/assets/icons/clock.svg"
      />
      <h4 class="mb-2 text-16 font-semibold">{{ mainMessage }}</h4>
      <p
        class="text-center text-14 font-medium text-hoar"
        v-html="subMessage"
      ></p>
    </div>

    <template v-if="userStore.user?.owner" #footer>
      <app-button class="mx-auto w-full" type="texted"
        >Перейти к тарифам
      </app-button>
    </template>
  </app-dialog>
</template>

<script lang="ts" setup>
import AppDialog from "@/shared/UIKit/app-dialog/app-dialog.vue";
import AppButton from "@/shared/UIKit/app-button/app-button.vue";
import { useUserStore } from "@/stores/user/user";
import { computed, ref, watch } from "vue";

const userStore = useUserStore();
const isOpen = ref(false);

watch(
  () => userStore.user,
  () => {
    if (userStore.user) {
      // isOpen.value = !userStore.user.isTrial;
    }
  },
);

const mainMessage = computed(() => {
  if (!userStore.user) {
    return "";
  }

  if (userStore.user.owner) {
    return "Пробный тариф подошел к концу";
  }

  return "Действие тарифа завершено";
});

const subMessage = computed(() => {
  if (!userStore.user) {
    return "";
  }

  if (userStore.user.owner) {
    return "Продлите тариф для дальнейшего <br> использования платформы";
  }

  return "Функционал платформы недоступен, <br> обратитесь к вашему руководителю";
});
</script>

<style scoped></style>
