<template>
  <div
    :class="{
      'app-radio--active': model === item.value,
      'app-radio--disabled': disabled,
    }"
    class="app-radio relative flex cursor-pointer items-center gap-2"
    @click="handleInputChange"
  >
    <input
      ref="inputRef"
      :class="{ disabled: disabled }"
      :disabled="disabled"
      :name="name || item.text"
      :value="item.value"
      class="radio-input z-10 cursor-pointer"
      type="radio"
    />
    <span
      :class="{ 'radio-custom--active': model === item.value }"
      class="radio-custom cursor-pointer"
    ></span>
    <label
      :for="item.value"
      class="app-radio-label w-full cursor-pointer font-medium"
      @click="inputRef?.click()"
    >
      <slot>{{ item.text }}</slot>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

interface Props {
  item: { text: string; value: string };
  disabled?: boolean;
  name?: string;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  name: "",
});

const inputRef = ref<null | HTMLElement>(null);
const model = defineModel<string>({ required: true });

const handleInputChange = (evt: any) => {
  model.value = props.item.value;
  evt.target.checked = false;
};
</script>

<style lang="scss" scoped>
.app-radio {
  .radio-custom {
    display: inline-block;
    border-radius: 50%;
    @apply h-[18px] min-h-[18px] w-[18px] min-w-[18px] border border-hoar/35 bg-white;
    position: relative;
    transition: all 0.2s;

    &--active {
      @apply border-primary;
      &:after {
        content: "";
        width: 10px;
        height: 10px;
        @apply bg-primary #{!important};
        border-radius: 50%;
        @apply left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
        position: absolute;
      }
    }
  }

  &--disabled {
    @apply pointer-events-none;

    .radio-custom {
      @apply bg-hoar/10;
    }

    .radio-custom--active {
      @apply opacity-40 bg-white;
    }
  }

  &:hover {
    .radio-custom {
      &:after {
        content: "";
        width: 10px;
        height: 10px;
        @apply bg-hoar/35;
        border-radius: 50%;
        @apply left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
        position: absolute;
      }
    }
  }
}

.radio-input {
  opacity: 0;
  position: absolute;
  top: 0;
  width: 18px;
  height: 18px;

  &.disabled {
    pointer-events: none;
  }
}
</style>
