import { ref, watch} from "vue";
import { defineStore } from "pinia";
import type { Mark } from "prosemirror-model";
import type { DocumentTree } from "@boostbiz/wiki_client/schemas/documents/documents.schema";
import type { ArticleResponse } from "@boostbiz/wiki_client/schemas/articles/articles.schema";
import type { CreateQuestionFormDTO } from "@boostbiz/testing_form_microservice/client/schemas";
import type {
  CreateQuestionFormDTO as CreateSurveyDTO,
  CreateQuestionFormTemplateDTO,
} from "@boostbiz/question_form_microservice/client/schemas";
import {
  getDefaultsTestData,
  getDefaultsTestTemplateData,
} from "@/modules/knowledge-base/views/testing-survey/testing/testing.utils";
import type CreateForm from "@/modules/knowledge-base/views/testing-survey/testing/create-form.vue";
import QuestionsList from "@/modules/knowledge-base/views/testing-survey/testing/questions-list.vue";
import {
  getDefaultsSurveyData,
  getDefaultsSurveyTemplateData,
} from "@/modules/knowledge-base/views/testing-survey/survey/survey.utils";
import { wikiApi } from "@/api";

export const useKnowledgeBaseStore = defineStore("knowledge", () => {
  const editor = ref<{ storedMarks?: readonly Mark[] | null }>({
    storedMarks: null,
  });
  const documentTree = ref<DocumentTree[]>([]);
  const mainUpdateToggle = ref(false);
  const sidebarUpdateToggle = ref(false);
  const search = ref("");
  const currentArticle = ref<ArticleResponse | null>(null);
  const collapsedDocumentsIds = ref<number[]>([]);

  const test = ref<CreateQuestionFormDTO>(getDefaultsTestData());
  const testFormRef = ref<InstanceType<typeof CreateForm>>();
  const testQuestionsRef = ref<InstanceType<typeof QuestionsList>>();

  const testPreview = ref<CreateQuestionFormDTO>(/*getDefaultsTestData()*/);

  const testTemplate = ref<CreateQuestionFormTemplateDTO>(
    getDefaultsTestTemplateData(),
  );
  const testTemplateFormRef = ref<InstanceType<typeof CreateForm>>();
  const testTemplateQuestionsRef = ref<InstanceType<typeof QuestionsList>>();

  const survey = ref<CreateSurveyDTO>(getDefaultsSurveyData());
  const surveyFormRef = ref<InstanceType<typeof CreateForm>>();
  const surveyQuestionsRef = ref<InstanceType<typeof QuestionsList>>();

  const surveyPreview = ref<CreateSurveyDTO>(/*getDefaultsSurveyData()*/);

  const surveyTemplate = ref<CreateQuestionFormTemplateDTO>(
    getDefaultsSurveyTemplateData(),
  );
  const surveyTemplateFormRef = ref<InstanceType<typeof CreateForm>>();
  const surveyTemplateQuestionsRef = ref<InstanceType<typeof QuestionsList>>();

  const fetchDocumentTree = async () => {
    documentTree.value = await wikiApi.getDocumentTree();
  };

  const searchDocuments = async () => {
    return await wikiApi.searchDocuments(search.value);
  };

  const setEditor = (val: { storedMarks?: readonly Mark[] }) => {
    editor.value = val;
  };
  //TODO Взаимодействовать с опросниками/тестированием ерез стор

  return {
    editor,
    setEditor,
    search,
    documentTree,
    collapsedDocumentsIds,
    mainUpdateToggle,
    sidebarUpdateToggle,
    currentArticle,
    fetchDocumentTree,
    searchDocuments,
    test,
    testFormRef,
    testQuestionsRef,
    survey,
    surveyFormRef,
    surveyQuestionsRef,
    surveyTemplate,
    surveyTemplateFormRef,
    surveyTemplateQuestionsRef,
    surveyPreview,
    testPreview,
    testTemplate,
    testTemplateFormRef,
    testTemplateQuestionsRef,
  };
});
